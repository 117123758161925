import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'cabin'
        let name = 'cabin'
        opt = {localKey, name}
        super(opt)
    }
    async getCourseLessonList(opt){
        opt = opt || {}
        let {page, size, course_id} = opt
		let options = {
			method: "get",
			data: {
				course_id,
                page,
                size,
			},
            ...opt
		}
		return this.requestAction('getCourseLessonList', options)
	}
    async getCourseLessonRecord(opt){
        opt = opt || {}
        let {course_id, user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
		let options = {
			method: "get",
			data: {
                ...opt,
				course_id,
			},
            ...opt
		}
		return this.requestAction('getCourseLessonRecord', options)
	}
}
